<template>
  <div class="content">
    <tabs
      :tabs="tabs"
      :currentTab="currentTab"
      wrapper-class="body-tabs shadow-tabs"
      tab-class="tab-item"
      tab-active-class="tab-item-active"
      line-class="tab-item-line"
      @onClick="changeTab"
    />
    <div v-if="currentTab === 'tab_table'">
      <b-row>
        <b-col md="12">
          <b-card class="mb-3" no-body>
            <div class="card-header">
              <i class="header-icon lnr-list icon-gradient bg-heavy-rain"></i>
              Liste des dossiers
              <div class="btn-actions-pane-right">
                <b-dropdown variant="outline-secondary" right :text="selectionLabel" :disabled="selection.length == 0">
                  <b-dropdown-item :href="exportUrl()">
                    <i class="text-muted far fa-fw fa-file-excel mr-2"></i>
                    Exporter (Excel)
                  </b-dropdown-item>
                  <b-dropdown-item-button @click="$refs.refuseModal.show()" v-if="admin">
                    <i class="text-muted fas fa-fw fa-ban mr-2"></i>
                    Refuser
                  </b-dropdown-item-button>
                  <b-dropdown-item-button class="text-danger" @click="deleteSelection()" v-if="admin && destroyAllowed">
                    <i class="fas fa-fw fa-trash mr-2"></i>
                    Supprimer
                  </b-dropdown-item-button>
                  <b-dropdown-divider />
                  <b-dropdown-item-button @click="clearSelection()">
                    Tout désélectionner
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
            <div class="card-body">
              <div class="mb-3 widget-content bg-light" v-show="activeFiltersCount > 0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-subheading">
                      <div class="badge badge-pill badge-secondary">
                        {{ activeFiltersCount }}
                      </div>
                      Filtres actifs
                    </div>
                  </div>
                  <div class="widget-content-right">
                    <button type="button" class="btn ml-3 btn-sm btn-icon btn-transition btn-outline-info" @click="changeTab('tab_filters')">
                      <i class="pe-7s-filter btn-icon-wrapper"></i>
                      Modifier
                    </button>
                    <button type="button" class="btn ml-3 btn-sm btn-icon btn-transition btn-outline-danger" @click="clearFilters()">
                      <i class="pe-7s-trash btn-icon-wrapper"></i>
                      Effacer
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6">
                  <h6 class="pt-1">
                    <div class="badge badge-pill badge-primary">
                      {{ count }} / {{ total }}
                    </div>
                    <span class="ml-1 align-middle">
                      Résultats
                    </span>
                  </h6>
                </div>
                <div class="col-md-5">
                  <b-form-group>
                    <b-input-group>
                      <b-form-input v-model.trim="search" placeholder="Recherchez par mot clé" />
                      <b-input-group-append>
                        <b-btn class="btn-sm" :disabled="!search" @click="search = ''">
                          Effacer
                        </b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-md-1">
                  <b-form-group>
                    <b-form-select :options="pagination_options" v-model="per" />
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-table
                    class="table-responsive border-0"
                    show-empty
                    striped
                    hover
                    bordered
                    no-local-sorting
                    stacked="md"
                    :items="items"
                    :busy="loading"
                    :fields="fields"
                    @sort-changed="sortChanged"
                    empty-text="Aucun dossier trouvé">
                    <div slot="table-busy" class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Chargement...</strong>
                    </div>
                    <template slot="HEAD_check">
                      <b-form-checkbox v-model="allSelected" @change="toggleAll" />
                    </template>
                    <template slot="check" slot-scope="data">
                      <b-form-checkbox :id="`selection-${data.item.id}`" v-model="selection" :value="data.item" />
                    </template>
                    <template slot="customer_name" slot-scope="data">
                      {{ data.item.customer_name }} <br />
                      {{ data.item.customer_email }} <br />
                      {{ data.item.customer_phone }}
                    </template>
                    <template slot="number" slot-scope="data">
                      <a :href="data.item.show_url">{{ data.item.number }}</a>
                    </template>
                    <template slot="actions" slot-scope="data">
                      <b-button :href="data.item.show_url" class="btn-sm btn-icon btn-icon-only" variant="outline-info" v-b-tooltip.hover title="Voir ce dossier">
                        <i class="pe-7s-look btn-icon-wrapper"></i>
                      </b-button>
                      <b-button v-if="data.item.edit_url" :href="data.item.edit_url" class="btn-sm btn-icon btn-icon-only" variant="outline-info" v-b-tooltip.hover title="Modifier ce dossier">
                        <i class="pe-7s-pen btn-icon-wrapper"></i>
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
            <b-pagination
              ref="pagination"
              size="md"
              align="center"
              :total-rows="count"
              :per-page="per"
              v-model="page" />
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="currentTab === 'tab_filters'">
      <b-row>
        <b-col md="12">
          <b-card class="mb-3" no-body>
            <form @submit.prevent="applyFilters()">
              <div class="card-header">
                <i class="header-icon lnr-magic-wand icon-gradient bg-heavy-rain"></i>
                Filtrer les résultats
              </div>
              <div class="card-body">
                <h5 class="card-title">Contexte</h5>
                <b-row>
                  <b-col cols="3">
                    <b-form-group>
                      <label :class="{'text-success': filters.organization_id}">Nom de l'organisme</label>
                      <b-form-select
                        v-model="filters.organization_id"
                        size="sm"
                        :options="organizations">
                        <template slot="first">
                          <option :value="null" disabled>Choisissez un organisme</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label :class="{'text-success': filters.residence_id}">Résidence</label>
                      <b-form-select
                        v-model="filters.residence_id"
                        size="sm"
                        :options="residences">
                        <template slot="first">
                          <option :value="null" disabled>Choisissez une résidence</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group>
                      <label :class="{'text-success': filters.unit_type_id}">Type de lot</label>
                      <b-form-select
                        v-model="filters.unit_type_id"
                        size="sm"
                        :options="unitTypes">
                        <template slot="first">
                          <option :value="null" disabled>Choisissez un type de lot</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr class="mt-3">
                <h5 class="card-title">Caractéristiques</h5>
                <b-row>
                  <b-col cols="2">
                    <b-form-group>
                      <label :class="{'text-success': filters.title}">Numéro</label>
                      <input type="text" class="form-control form-control-sm" v-model="filters.title">
                    </b-form-group>
                  </b-col>
                  <b-col cols="10">
                    <b-form-group>
                      <label :class="{'text-success': filters.signed_document_model_ids}">Type de contrat</label>
                      <b-form-select
                        v-model="filters.signed_document_model_ids"
                        size="sm"
                        :options="signedDocumentModels">
                        <template slot="first">
                          <option :value="null" disabled>Choisissez un type de contrat</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group>
                      <label :class="{'text-success': filters.customer_id}">Client</label>
                      <search-select
                        :url="customersUrl"
                        v-model="filters.customer_id"
                        order="last_name"
                        direction="asc"
                      >
                        <template slot="selected-option" slot-scope="customer">
                          {{ customer.last_name }} {{ customer.first_name }}
                        </template>
                        <template slot="option" slot-scope="customer">
                          {{ customer.last_name }} {{ customer.first_name }}
                          <span class="text-muted">({{ customer.email }})</span>
                        </template>
                      </search-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label :class="{'text-success': filters.partner_id}">Partenaire</label>
                      <search-select
                        :url="partnersUrl"
                        v-model="filters.partner_id"
                        order="title"
                        direction="asc"
                      >
                        <template slot="selected-option" slot-scope="partner">
                          {{ partner.title }} ({{ partner.code }})
                        </template>
                        <template slot="option" slot-scope="partner">
                          {{ partner.title }} ({{ partner.code }})
                        </template>
                      </search-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group>
                      <label :class="{'text-success': filters.status}">Statut</label>
                      <b-form-select
                        v-model="filters.status"
                        size="sm"
                        :options="statuses">
                        <template slot="first">
                          <option :value="null" disabled>Choisissez un statut</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label :class="{'text-success': filters.state}">État</label>
                      <b-form-select
                        name="record_state"
                        v-model="filters.state"
                        size="sm">
                        <optgroup v-for="status in statuses" :label="status.text" v-if="statesFromStatus(status).length > 0">
                          <option v-for="state in statesFromStatus(status)" :value="state.value">
                            {{ state.text }}
                          </option>
                        </optgroup>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <datepicker :range="true"
                              v-model="filters.agreement_given_at"
                              label="Début du contrat"
                              subtitle="Choisissez une période" />
                  <datepicker :range="true"
                              v-model="filters.contract_finished_at"
                              label="Fin de contrat"
                              subtitle="Choisissez une période" />
                  <datepicker type="create"
                              :range="true"
                              v-model="filters.desired_started_at"
                              label="Date d'entrée souhaitée"
                              subtitle="Choisissez une période" />
                </b-row>
                <hr class="mt-3">
                <h5 class="card-title">Informations locataire</h5>
                <b-row>
                  <b-col cols="12">
                    <b-form-group>
                      <label>Nom de l'établissement</label>
                      <search-select
                        :url="schoolsUrl"
                        v-model="filters.school_id"
                        order="title"
                        direction="asc"
                      >
                        <template slot="selected-option" slot-scope="school">
                          {{ school.title }} - {{ school.university }} - {{ school.city }}
                        </template>
                        <template slot="option" slot-scope="school">
                          {{ school.title }}
                          <span class="text-muted">{{ school.university }} - {{ school.city }}</span>
                        </template>
                      </search-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <label>Boursier&nbsp;?</label>
                    <b-form-radio-group
                      v-model="filters.scholarship_owner"
                      :options="[{ text: 'Oui', value: true }, { text: 'Non', value: false }, { text: 'Tous', value: null }]" />
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Situation pour le contrat</label>
                      <b-form-select
                        v-model="filters.contract_situation"
                        size="sm"
                        :options="contractSituations">
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr class="mt-3">
                <h5 class="card-title">Enregistrement</h5>
                <div class="form-row">
                  <datepicker type="create" :range="true" v-model="filters.created_at"></datepicker>
                  <div class="col-md-8">
                    <label>Créé par</label>
                    <div class="position-relative form-group">
                      <select class="form-control form-control-sm">
                        <option>TODO</option>
                      </select>
                      <small class="form-text text-muted">
                        Le créateur de l'enregistrement
                      </small>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <datepicker type="update" :range="true" v-model="filters.updated_at"></datepicker>
                  <div class="col-md-8">
                    <label>Modifié par</label>
                    <div class="position-relative form-group">
                      <select class="form-control form-control-sm">
                        <option>TODO</option>
                      </select>
                      <small class="form-text text-muted">
                        Le dernier éditeur de l'enregistrement
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-block text-right card-footer">
                <button type="button" data-toggle="button" aria-pressed="true" class="btn btn-lg btn-link active float-left" @click="changeTab('tab_table')">
                  <i class="pe-7s-back"></i>
                  Retour à la liste
                </button>
                <button type="button" class="btn mr-2 btn-transition btn-outline-danger btn-lg" @click="clearFilters()">
                  Supprimer les filtres
                </button>
                <button class="btn btn-hover-shine btn-transition btn-primary btn-lg" type="submit">
                  Appliquer les filtres
                </button>
              </div>
            </form>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-modal ref="refuseModal" centered hide-footer title="Refus de dossiers">
      <b-alert show variant="info">
        <em>Important :</em>
        seuls les dossiers actuellement <strong>à traiter</strong> seront effectivement refusés.
      </b-alert>
      <b-form>
        <b-form-group label="État de refus :">
          <b-form-select
            v-model="refusalState"
            :options="refusalStates">
          </b-form-select>
        </b-form-group>
      </b-form>
      <hr class="my-4">
      <div class="text-right">
        <b-button variant="outline" @click="$refs['refuseModal'].hide()">
          Annuler
        </b-button>
        <b-button variant="danger" :disabled="processing" @click="refuseSelection">
          <span v-show="!processing">Refuser {{ selection.length }} dossier(s)</span>
          <span v-show="processing">Patientez...</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import moment from 'moment'
  import DatePicker from 'vue2-datepicker'
  import axios from 'axios'
  import Index from '../mixins/Index'

  const fields = [
    {
      key: "check",
      label: "",
      sortable: false,
      tdClass: 'fit text-center'
    },
    {
      key: "number",
      label: "N° de dossier",
      sortable: true,
      tdClass: 'fit',
      thClass: 'fit'
    }, {
      key: "customer_name",
      label: "Candidat",
      sortable: false,
      tdClass: 'fit'
    }, {
      key: "state",
      label: "État",
      sortable: true
    }, {
      key: "residence_title",
      label: "Résidence",
      sortable: false
    }, {
      key: "unit_type_title",
      label: "Type de lot",
      sortable: false,
      tdClass: 'fit'
    }, {
      key: "created_at",
      label: "Date de création",
      sortable: true,
      formatter: (value) => value ? moment(value).format('DD/MM/YYYY') : null,
      tdClass: 'fit',
      thClass: 'fit'
    }, {
      key: "desired_started_at",
      label: "Entrée souhaitée",
      sortable: true,
      formatter: (value) => value ? moment(value).format('DD/MM/YYYY') : null,
      tdClass: 'fit',
      thClass: 'fit'
    }, {
      key: "agreement_given_at",
      label: "Date début contrat",
      sortable: true,
      formatter: (value) => value ? moment(value).format('DD/MM/YYYY') : null,
      tdClass: 'fit',
      thClass: 'fit'
    }, {
      key: "actions",
      label: '',
      sortable: false,
      tdClass: 'fit'
    }
  ]

  export default {
    mixins: [
      Index
    ],
    components: {
      DatePicker
    },
    props: {
      admin: Boolean,
      organizations: Array,
      residences: Array,
      unitTypes: Array,
      signedDocumentModels: Array,
      states: Array,
      statuses: Array,
      schoolsUrl: String,
      customersUrl: String,
      partnersUrl: String,
      destroyAllowed: Boolean,
      contractSituations: Array
    },
    data() {
      return {
        fields,
        refusalState: 'refused_minor_priority',
        processing: false
      }
    },
    computed: {
      selectionLabel() {
        switch(this.selection.length) {
          case 0:
            return 'Aucune sélection'
          case 1:
            return '1 dossier sélectionné'
          default:
            return `${this.selection.length} dossiers sélectionnés`
        }
      },
      refusalStates() {
        return _.filter(this.states, (state) => {
          return state.value.startsWith('refused_')
        })
      }
    },
    methods: {
      statesFromStatus(status) {
        return _.filter(this.states, (state) => {
          return this.statusFromState(state.value) == status.value
        })
      },
      statusFromState(state) {
        return _.split(state, '_')[0]
      },
      refuseSelection() {
        if (this.processing) return
        this.processing = true
        axios.post(`${this.url}/refusal`, {
          state: this.refusalState,
          ids: this.selection.map(unit => unit.id)
        }).then((response) => {
          this.fetch()
          this.$refs.refuseModal.hide()
          this.processing = false
          this.clearSelection()
        })
      },
      deleteSelection() {
        if (this.selection.length == 0) return
        let message
        if (this.selection.length > 1) {
          message = `Êtes-vous sûr(e) de vouloir supprimer les ${this.selection.length} dossiers sélectionnés ?`
        } else {
          message = "Êtes-vous sûr(e) de vouloir supprimer le dossier sélectionné ?"
        }
        message = message + "\n\nRemarque : la suppression ne s'appliquera qu'aux dossiers des organismes dont vous êtes administrateur."
        swal({
          text: message,
          buttons: {
            cancel: "Ne pas supprimer",
            confirm: {
              text: "Oui, supprimer",
              closeModal: false
            }
          },
          dangerMode: true
        }).then((response) => {
          if (response) {
            axios.delete(this.url, {
              params: {
                ids: this.selection.map(unit => unit.id)
              }
            }).then((response) => {
              this.fetch()
              swal.stopLoading()
              swal.close()
            })
          }
        })
      }
    }
  }
</script>
