<template>
  <div>
    <hr class="my-3">
    <h5 class="card-title">CONTRAT</h5>
    <div class="form-row mt-3">
      <div class="col-md-12">
        <label>Logement ou Parking</label>
        <div class="position-relative form-group">
          <b-alert :show="!availableUnits" variant="warning">
            <i class="fas fa-exclamation-circle"></i>
            Aucun logement ou parking n'est disponible sur l'ensemble des résidences que vous gérez.
          </b-alert>
          <search-select
            v-if="availableUnits"
            :url="unitsUrl"
            :query="{status: 'available'}"
            v-model="model.unit_id"
            :disabled="readonly || !assignableUnit"
            :state="state('record_unit_id')"
            @results="units = $event"
            @change="fetchUnitPricings(true)"
          >
            <template slot="selected-option" slot-scope="unit">
              {{ unit.residence_title }} - {{ unit.unit_type_kind_title }} - {{ unit.number }}
            </template>
            <template slot="option" slot-scope="unit">
              <span class="text-primary">{{ unit.residence_title }}</span>
              {{ unit.unit_type_kind_title }} - {{ unit.number }}
            </template>
          </search-select>
          <b-form-invalid-feedback>
            {{ errors.first('record_unit_id') }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row" v-if="isSignedDocumentModelListRequired">
      <div class="col-md-12 mb-3">
        <label>
          Liste de documents de contrat
          <span v-show="isSignedDocumentModelListRequired">*</span>
        </label>
        <b-form-select
          name="record_signed_document_model_list"
          :disabled="readonly"
          v-model="model.signed_document_model_list_id"
          v-validate="{ required: isSignedDocumentModelListRequired }"
          :options="availableSignedDocumentModelLists"
          :state="state('record_signed_document_model_list')"
          size="sm">
          <template slot="first">
            <option :value="null" disabled>Choisissez une liste</option>
          </template>
        </b-form-select>
        <b-form-invalid-feedback>
          {{ errors.first('record_signed_document_model_list') }}
        </b-form-invalid-feedback>
      </div>
    </div>
    <div v-if="units != null && units.length > 0">
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label>Tarif</label>
          <b-form-select
            :disabled="readonly || model.unit_pricing_set_at !== null"
            v-model="selectedPricingId"
            :options="availablePricings"
            @change="fetchUnitPricings"
            size="sm">
          </b-form-select>
        </div>
      </div>
      <div class="form-row mb-3">
        <div class="col-md-4">
          <label>Loyer Hors Charges</label>
          <b-input-group size="sm" append="€">
            <b-form-input
              readonly
              type="number"
              v-model="model.rent_amount_without_charges" />
          </b-input-group>
        </div>
        <div class="col-md-4">
          <label>Forfait charges</label>
          <b-input-group size="sm" append="€">
            <b-form-input
              readonly
              type="number"
              v-model="model.charges_amount" />
          </b-input-group>
        </div>
        <div class="col-md-4">
          <label>Prestations Annexes</label>
          <b-input-group size="sm" append="€">
            <b-form-input
              readonly
              type="number"
              v-model="model.annex_rent_amount" />
          </b-input-group>
        </div>
      </div>
      <div class="form-row mb-3">
        <div class="col-md-4">
          <label>Loyer TTC & TCC</label>
          <b-input-group size="sm" append="€">
            <b-form-input
              readonly
              type="number"
              v-model="model.rent_amount" />
          </b-input-group>
        </div>
        <div class="col-md-4">
          <label>Dépôt de garantie</label>
          <b-input-group size="sm" append="€">
            <b-form-input
              readonly
              type="number"
              v-model="model.deposit_amount" />
          </b-input-group>
        </div>
        <div class="col-md-4">
          <label>Prestations d'entrée</label>
          <b-input-group size="sm" append="€">
            <b-form-input
              readonly
              type="number"
              v-model="model.packages_amount" />
          </b-input-group>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4">
          <label>Loyer accessoire</label>
          <b-input-group size="sm" append="€">
            <b-form-input
              readonly
              type="number"
              v-model="model.auxiliary_amount" />
          </b-input-group>
        </div>
        <div class="col-md-4">
          <label>Frais électricité</label>
          <b-input-group size="sm" append="€">
            <b-form-input
              readonly
              type="number"
              v-model="model.electricity_costs" />
          </b-input-group>
        </div>
        <div class="col-md-4">
          <label>Caution</label>
          <b-input-group size="sm" append="€">
            <b-form-input
              readonly
              type="number"
              v-model="model.additional_deposit_amount" />
          </b-input-group>
        </div>
      </div>
    </div>
    <div class="form-row mt-3">
      <div class="col-md-4">
        <label>
          Date de début du contrat
          <span v-show="isAgreementGivenAtRequired">*</span>
        </label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            name="record_agreement_given_at"
            type="text"
            class="form-control form-control-sm"
            v-mask="'##/##/####'"
            placeholder="JJ/MM/AAAA"
            v-model="model.agreement_given_at"
            v-validate="{ required: isAgreementGivenAtRequired, date_format: 'dd/MM/yyyy' }"
            :state="state('record_agreement_given_at')"
          />
          <b-form-invalid-feedback>
            {{ errors.first('record_agreement_given_at') }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-md-4">
        <label>Date de fin de contrat</label>
        <div class="position-relative form-group">
          <b-form-input
            :readonly="readonly"
            name="record_contract_finished_at"
            type="text"
            class="form-control form-control-sm"
            v-mask="'##/##/####'"
            placeholder="JJ/MM/AAAA"
            v-model="model.contract_finished_at"
            v-validate="{ date_format: 'dd/MM/yyyy' }"
            :state="state('record_contract_finished_at')"
          />
          <b-form-invalid-feedback>
            {{ errors.first('record_contract_finished_at') }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="form-row mt-3">
      <div class="col-md-12">
        <label>Observations</label>
        <div class="position-relative form-group">
          <b-form-textarea
            :readonly="readonly"
            name="record_comment"
            class="form-control form-control-sm"
            v-model="model.comment"
            :state="state('record_comment')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from '../mixins/Form'
  import DatePicker from 'vue2-datepicker'
  import axios from 'axios'

  export default {
    mixins: [
      Form
    ],
    components: {
      DatePicker
    },
    props: {
      unitsUrl: String,
      pricings: Object,
      assignableUnit: Boolean,
      availableUnits: Boolean,
      signedDocumentModelLists: Array,
      defaultArCodePrefix: String
    },
    data() {
      return {
        units: null,
        availablePricings: [],
        availableSignedDocumentModelLists: [],
        selectedPricingId: null,
        amounts: [
          'rent_amount',
          'rent_amount_without_charges',
          'charges_amount',
          'deposit_amount',
          'additional_deposit_amount',
          'annex_rent_amount',
          'packages_amount',
          'auxiliary_amount',
          'electricity_costs'
        ]
      }
    },
    created() {
      this.fetchUnitPricings()
    },
    computed: {
      isAgreementGivenAtRequired() {
        return this.model.unit_id !== null
      },
      isSignedDocumentModelListRequired() {
        return this.model.unit_id !== null
      }
    },
    methods: {
      setDefaultArCodePrefix() {
        if (!this.readonly && this.model.ar_code === null || this.model.ar_code == '') {
          this.model.ar_code = this.defaultArCodePrefix
        }
      },
      removeDefaultArCodePrefix() {
        if (!this.readonly && this.model.ar_code === this.defaultArCodePrefix) {
          this.model.ar_code = ''
        }
      },
      fetchUnitPricings(onUnitChange = false) {
        if (!this.model.unit_id) {
          return
        }

        axios.get('/admin/units/' + this.model.unit_id).then(resp => {
          const sdmlAttributes = resp.data.signed_document_model_lists_attributes
          this.availableSignedDocumentModelLists = this.signedDocumentModelLists.filter(p => _.includes(sdmlAttributes.map(sdml => sdml.id), p.value))
          if(onUnitChange) {
            if(this.availableSignedDocumentModelLists.length > 0) {
              this.model.signed_document_model_list_id = this.availableSignedDocumentModelLists[0].value
            } else {
              this.model.signed_document_model_list_id = null
            }
          }

          const unitPricingsAttributes = resp.data.unit_pricings_attributes
          this.availablePricings = this.pricings.options.filter(p => _.includes(unitPricingsAttributes.map(up => up.pricing_id), p.value))
          if (!this.selectedPricingId) {
            this.selectedPricingId = this.pricings.current.id
          }

          const unitPricing = unitPricingsAttributes.find(up => up.pricing_id === this.selectedPricingId)
          if (!this.model.unit_pricing_set_at) {
            this.model.unit_pricing_id = unitPricing.id
            this.amounts.forEach(amount => {
              this.model[amount] = unitPricing[amount]
            })
          }
        })
        // TODO: v-validate ne détecte pas le changement "required: isAgreementGivenAtRequired"
        if (!this.readonly) {
          this.$nextTick(() => {
            this.$validator.validate('record_agreement_given_at')
            this.$validator.validate('record_signed_document_model_list')
          })
        }
      }
    }
  }
</script>
