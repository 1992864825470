<template>
  <div>
    <h5 class="card-title">
      Messages
    </h5>
    <div v-if="collection.length == 0">
      <b-alert class="text-center" show>Aucun message pour l'instant.</b-alert>
    </div>
    <div v-else class="chat-wrapper p-1">
      <div v-for="message in orderedMessages" :key="`message-${message.id}`">
        <div :class="{ 'float-right': !message.is_customer && !message.mailer_content }">
          <div class="chat-box-wrapper mb-3" :class="{ 'chat-box-wrapper-right': !message.is_customer }">
            <div :class="{ 'flex-grow-1': message.mailer_content }">
              <p class="mb-0" v-if="message.system">
                {{ message.content }}
              </p>
              <div class="chat-box" v-else>
                {{ message.content }}
              </div>
              <div class="chat-box" v-if="message.mailer_content" style="background: #ddd;">
                <iframe :srcdoc="displayMailerContent(message)" frameborder="0" width="100%"></iframe>
              </div>
              <small class="opacity-6 mx-0">
                <strong class="mr-2">
                  {{ message.sender }}
                </strong>
                <i class="fas fa-calendar-alt mr-1 fa-w-14"></i>
                {{ message.created_at | moment('DD/MM/YYYY HH:mm:ss') }}
              </small>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <b-input-group>
      <b-form-input v-model="content" @keyup.enter="submit()" />
      <b-input-group-append>
        <b-button
          :disabled="content == ''"
          variant="primary"
          @click="submit()">
          Envoyer
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    props: {
      messages: Array,
      action: String,
      read_url: String,
      markAsRead: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        content: '',
        collection: this.messages,
        submitting: false
      }
    },
    computed: {
      orderedMessages() {
        return _.orderBy(this.collection, 'created_at', 'asc')
      }
    },
    methods: {
      read() {
        axios.post(this.read_url, {
        }).then(response => {
        }).catch(error => {
          console.log(error)
        })
      },
      submit() {
        if (!this.submitting && this.content != '') {
          this.submitting = true
          axios({
            method: 'post',
            url: this.action,
            data: {
              message: {
                content: this.content
              }
            }
          }).then(response => {
            this.content = ''
            this.collection.push(response.data)
            this.submitting = false
          })
        }
      },
      displayMailerContent(message) {
        return message.mailer_content.replace(/<a href=".+">(.+)<\/a>/gm, "$1")
      }
    },
    watch: {
      markAsRead: 'read'
    }
  }
</script>
