<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <b-card class="mb-3" no-body>
          <b-form autocomplete="off">
            <div class="card-header">
              <i class="header-icon lnr-pencil icon-gradient bg-heavy-rain"></i>
              {{ action_type }} un contrat
            </div>
            <error-messages :messages="messages" />
            <b-tabs card>
              <b-tab title="Général" active>
                <h5 class="card-title">INFORMATIONS</h5>
                <div class="form-row mt-3">
                  <div class="col-md-4">
                    <label>Type*</label>
                    <b-form-select
                      :disabled="readonly"
                      v-model="model.type"
                      name="signed_document_model_type"
                      v-validate="'required'"
                      :options="types"
                      size="sm"
                    >
                      <template slot="first">
                        <option :value="null" disabled
                          >Choisissez un type de contrat</option
                        >
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <hr class="mt-4" />
                <div class="row">
                  <div
                    class="col-md-6"
                    v-for="locale in this.$locales"
                    :key="locale"
                  >
                    <h5 class="card-title">{{ locale }}</h5>
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>Intitulé*</label>
                        <b-form-input
                          :readonly="readonly"
                          :name="`signed_document_model_title_${locale}`"
                          type="text"
                          class="form-control form-control-sm"
                          v-model="model['title_' + locale]"
                          v-validate="'required'"
                          :state="
                            state(`signed_document_model_title_${locale}`)
                          "
                        />
                        <b-form-invalid-feedback>
                          {{
                            errors.first(
                              `signed_document_model_title_${locale}`
                            )
                          }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-row mt-3">
                      <div class="col-md-12">
                        <label>Description</label>
                        <b-form-textarea
                          :readonly="readonly"
                          :name="`signed_document_model_description_${locale}`"
                          class="form-control form-control-sm"
                          v-model="model['description_' + locale]"
                          data-vv-delay="500"
                          :state="
                            state(`signed_document_model_description_${locale}`)
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label>Fichier*</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <b-form-file
                      v-model="model.pdf_file"
                      name="signed_document_model_pdf_file"
                      :state="state('signed_document_model_pdf_file')"
                      placeholder="Choisissez un fichier..."
                      browse-text="Parcourir"
                      drop-placeholder="Déposez votre fichier ici..."
                      accept=".pdf"
                      v-validate="pdfFileRules"
                    ></b-form-file>
                    <b-form-text>
                      Au format .pdf
                    </b-form-text>
                  </div>
                  <div class="col-md-4" v-if="model.pdf_file_url">
                    <b-button
                      :href="model.pdf_file_url"
                      target="_blank"
                      variant="outline-secondary"
                    >
                      <i class="fas fa-download"></i>
                      {{ model.pdf_file_name }}
                    </b-button>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label>Données Yousign*</label>
                    <b-form-textarea
                      :readonly="readonly"
                      name="signed_document_model_yousign_data"
                      class="form-control form-control-sm"
                      v-model="model.yousign_data"
                      v-validate="'required|json'"
                      :state="state('signed_document_model_yousign_data')"
                    />
                    <b-form-invalid-feedback>
                      {{ errors.first("signed_document_model_yousign_data") }}
                    </b-form-invalid-feedback>
                    <b-form-text>
                      Au format JSON&nbsp;: { guarantor1: { "page": XXX, "x":
                      XXX, "y": XXX, "width": XXX, "height": YYY }, stamp: {
                      "page": XXX, "x": XXX, "y": XXX, "width": XXX, "height":
                      YYY } }
                    </b-form-text>
                  </div>
                </div>
                <!-- TODO : à supprimer après passage sur API Yousign v3 -->
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label>Données Yousign* API V2</label>
                    <b-form-textarea
                      :readonly="readonly"
                      name="signed_document_model_v2_yousign_data"
                      class="form-control form-control-sm"
                      v-model="model.v2_yousign_data"
                      v-validate="'required|json'"
                      :state="state('signed_document_model_v2_yousign_data')"
                    />
                    <b-form-invalid-feedback>
                      {{
                        errors.first("signed_document_model_v2_yousign_data")
                      }}
                    </b-form-invalid-feedback>
                    <b-form-text>
                      Au format JSON&nbsp;: { guarantor1: "xxx,xxx,xxx,xxx",
                      stamp: "xxx,xxx,xxx,xxx", page: x }
                    </b-form-text>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <label>Date de publication</label>
                    <div class="form-group">
                      <date-picker
                        :disabled="readonly"
                        name="signed_document_model_published_at"
                        v-model="model.published_at"
                        @clear="
                          (clear) => {
                            model.published_at = '';
                          }
                        "
                        lang="fr"
                        format="DD/MM/YYYY"
                      >
                      </date-picker>
                    </div>
                  </div>
                </div>
                <item-information :value="value" />
              </b-tab>
            </b-tabs>
            <div class="d-block text-right card-footer">
              <a
                :href="cancel_url"
                class="btn btn-lg btn-link active float-left"
              >
                <i class="pe-7s-back"></i>
                Retour à la liste
              </a>
              <a
                v-if="!readonly"
                :href="cancel_url"
                class="btn mr-2 btn-transition btn-outline-light btn-lg"
              >
                Annuler
              </a>
              <b-button
                v-if="!readonly"
                class="btn btn-hover-shine btn-transition btn-primary btn-lg"
                :disabled="invalid()"
                @click.stop.prevent="submit()"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "../mixins/Form";
import JsonValidator from "../mixins/JsonValidator";
import DatePicker from "vue2-datepicker";

export default {
  mixins: [Form, JsonValidator],
  components: {
    DatePicker,
  },
  props: {
    types: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    pdfFileRules() {
      return this.model.pdf_file_url ? "" : "required";
    },
  },
};
</script>
